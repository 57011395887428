.RoomCard {
  cursor: pointer;
  border-radius: 4px;
  padding: 16px 16px 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.RoomCard.selected {
  border: 1.5px solid #5B5FC7;
}

.RoomCard h1 {
  font-size: 14px;
}