.RoomsModal .card{
  min-height: 200px;
  background: #eeeeee;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.RoomsModal .card .enabled-room {
  background: rgba(14, 197, 100, .08);
  border-radius: 0 10px;
  color: #75d385;
  font-size: 13px;
  line-height: 22px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 98px;
}

.RoomsModal .card h1 {
  align-items: center;
  color: #181818;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .0036em;
  line-height: 34px;
}

.RoomsModal .card ul {
  align-items: center;
  color: #6f6f6f;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -.0041em;
  line-height: 22px;
  list-style: none;
  margin: 8px 0 0;
  padding: 0;
}

.RoomsModal .card ul li {
  margin-bottom: 8px;
}

.RoomsModal .card ul li.list-amenities svg {
  margin-left: 7px;
  width: 12px;
}

.RoomsModal .info-wrapper {
  display: flex;
  align-items: center;
}

.RoomsModal .no-rooms-available {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RoomsModal .no-rooms-available h2 {
  max-width: 540px;
  text-align: center;
  font-weight: 200;
}

.RoomsModal .room-list {
  max-height: 400px;
  overflow-y: scroll;
}

.RoomListImageToggle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-content: flex-start;
  align-items: flex-start;
}

.RoomListImageToggle .ui-image {
  max-width: 100%;
  border-radius: 5px;
}

.RoomsModal .add-filters {
  width: 200px;
}