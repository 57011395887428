.RedirectPage {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 442;
    height: 544;
    position: absolute;
    border-radius: 17px;
    background: #FFF;
    box-shadow: 0px 0px 60px 0px rgba(196, 196, 196, 0.15);
    align-items: center;
    padding: 46px 45px;
    gap: 5px;
    box-sizing: content-box;
}

.RedirectPage h1 {
    color: #242424;
    font-family: "Segoe UI";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.RedirectPage p {
    text-align: center;
}