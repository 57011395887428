.TimeRangeSelector {
    height: calc(100vh - 220px);
    max-height: 572px;
    overflow-y: scroll;
    overflow-x: hidden;
    min-width: 300px;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper {
    position: relative;
    width: 100%;
    background-color: white;
    background-size: var(--ONE_HOUR) var(--ONE_HOUR);
    background-image: repeating-linear-gradient(0deg, #f7f7f7, #f7f7f7 1px, white 1px, white);
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-hours label {
    color: #9b9b9b;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    height: var(--ONE_HOUR);
    padding-right: 5px;
    box-sizing: border-box;
    width: 65px;
    background: white;
    text-align: right;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-new-event-wrapper {
    position: absolute;
    margin-left: 70px;
    width: 100%;
    min-height: var(--FIFTEEN_MINUTES);
    top: 0;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-new-event-wrapper.react-draggable-dragging .TimeRangeSelector-new-event .handle {
    cursor: grabbing;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-new-event-wrapper .TimeRangeSelector-new-event {
    background: red;
    border-radius: 8px;
    border-top: 2px solid #75d385;
    border-bottom: 2px solid #75d385;
    border-left: 3px solid #75d385;
    background: #ecfaf3;
    position: relative;
    box-sizing: border-box;
    padding: 8px 10px;
    width: calc(100% - 70px);
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-new-event-wrapper .TimeRangeSelector-new-event .react-resizable-handle {
    width: 17px;
    height: 13px;
    display: block;
    background: #75d385;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    cursor: n-resize;
    bottom: -8px;
    right: 20px;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-new-event-wrapper .TimeRangeSelector-new-event.has-collision {
    background: #FCF4F6;
    border-color: #C4314B;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-new-event-wrapper .TimeRangeSelector-new-event.has-collision .react-resizable-handle {
    background: #C4314B;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-new-event-wrapper .TimeRangeSelector-new-event .handle {
    height: 100%;
    cursor: grab;
    overflow: hidden;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-new-event-wrapper .TimeRangeSelector-new-event .handle .TimeRangeSelector-info {
    cursor: text;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-prev-reservations {
    width: calc(100% - 70px);
    position: absolute;
    right: 0;
    border: 1px solid #d2d2d2;
    background: #ffebed;
    padding: 8px 10px;
    font-size: 14px;
    color: #5f74b7;
    box-sizing: border-box;
}

.TimeRangeSelector .TimeRangeSelector-hours-wrapper .TimeRangeSelector-prev-reservations.disabled-time {
    background: #efefef;
}