:root {
  --parso-light-gray: #484644;
  --parso-light-gray-2: #616161;
  --parso-primary: #444791;
  --parso-super-light-gray: #D8D8D8;
  --parso-high-gray: #4A4A68;
  --parso-blue: #444791;
  --breakpoint: 768px;
}

* {
  box-sizing: border-box;
  /* outline: 1px dashed #f00; */
}

*::-webkit-scrollbar {
  background-color: transparent;
  cursor: pointer;
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: #C8C5C54D;
  border-radius: 4px;
}

body {
  margin: 5em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

.NewReservation {
  padding: 17px 29px 150px;
  background-color: #eeeeee;
}

.NewReservation .ui-form {
  justify-content: flex-start;
}

.NewReservation .gq> :last-child {
  margin-top: initial;
}

.NewReservation .ui-datepicker .ui-box:first {
  width: 268px;
}

.NewReservation .ui-datepicker input.ui-box,
.NewReservation .input-desk,
.NewReservation .ui-dropdown__container,
.NewReservation .ui-form__input input.ui-box,
.NewReservation .ui-form__textarea textarea {
  background: white;
  width: calc(100vw - 150px);
  max-width: 320px;
}

.NewReservation .datetime-form .ui-form__input input.ui-box {
  width: 140px;
}

.form-label {
  min-width: 67px
}

.NewReservation form .el,
.NewReservation form .ei,
.NewReservation form .fq {
  font-weight: normal;
}

.NewReservation .ui-input__input {
  width: 321px;
}

.NewReservation .info-attendees {
  width: 321px;
}

.input-icon {
  min-width: 16px;
  width: 16px;
}

.status-icon {
  color: #237B4B
}

.status-icon.partially-available {
  color: #E28834;
  background: rgba(255, 248, 237, 1);
}

.status-icon.not-available {
  color: #CD2A26;
  background: rgba(255, 235, 237, 1);
}

.subtitle-1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
}

.caption-text {
  font-size: 14px;
  font-weight: 400;
}

.aq.aq> :not(:last-child) {
  margin-right: 1rem;
}

.select-type-reservation.select-type-reservation {
  width: 398px;
}

.type-icon {
  width: 14px;
  margin-right: 10px;
}

.Reservations .add-reservation {
  display: none;
  position: fixed;
  bottom: 28px;
  right: 24px;
  padding: 26px 18px;
}

.Reservations .add-reservation>.ui-box {
  padding-bottom: 15px;
}

.NewReservation .fq> :last-child {
  margin-top: 0
}

.legend {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;
  color: #4A4A68;
  letter-spacing: 0.015em;
}

.legend label {
  margin-right: 15px;
  font-weight: normal;
  font-size: 15px;
}

.legend svg {
  margin-right: 2px;
}

.NewReservation .input-desk input.ui-box {
  width: 100%;
}

.detail-map-header h1 {
  color: #201F1E;
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
}

.detail-map-header h2 {
  color: rgba(32, 31, 30, 0.58);
  font-size: 15px;
  margin: 0px;
}

.code-alert.code-alert {
  min-height: auto;
}

@media only screen and (max-width: 768px) {

  .Reservations .add-reservation {
    display: block;
  }
}