.Load {
  background: #6C85D3;
  height: 100vh;
}

.Load .box-loading {
    padding: 0 30px;
    max-width: 500px;
    height: 185px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
}

.Load .box-loading svg {
    margin-bottom: 64px;
}