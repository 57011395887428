.global-loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.939);
}

.global-loader>div {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}