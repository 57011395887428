@media only screen and (min-width: 769px) {
  .hide-movil {
    display: inherit !important;
  }

  .hide-web {
    display: none !important;
  }

}

@media only screen and (max-width: 768px) {
  .hide-movil {
    display: none !important;
  }

  .hide-web {
    display: inherit !important;
  }
}

.hide {
  display: none !important;
}