.schedule-selector .MuiDialog-container .MuiPaper-root {
    max-width: 100%;
    row-gap: 10px;
    max-height: 100%;
    margin: 0;
}

.schedule-selector .MuiDialogActions-root {
    justify-content: center !important;
    padding: 30px 54px 40px 54px;
}

.dialog-schedule-selector-content {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    padding: 0 10px 20px !important;
}

.schedule-selector-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    column-gap: 20px;
}

.schedule-selector-header h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #525252;
}

.schedule-selector-header svg {
    width: 32px;
    height: 32px;
}

.schedule-selector-header svg path {
    fill: #6c85d3;
}

.schedule-selector-legend {
    font-size: 14px;
    color: #4a4a4a;
    display: flex;
    align-items: baseline;
    column-gap: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.schedule-selector-legend svg {
    width: 12px;
    height: 12px;
}

.schedule-selector-legend svg circle {
    fill: #C4314B;
}

.book-room-button {
    background: #6c85d3;
    width: 100%;
    height: 52px;
    border-radius: 8px !important;
    font-size: 18px !important;
}

@media screen and (min-width: 600px) {
    .schedule-selector .MuiDialog-container .MuiPaper-root {
        max-height: calc(100% - 64px);
        margin: 32px;
    }

    .MuiDialogTitle-root {
        padding: 40px 57px 0;
    }

    .dialog-schedule-selector-content {
        padding: 0 54px 20px !important;
    }

    .schedule-selector-header {
        align-items: stretch;
    }
}