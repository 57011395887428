.welcome-box {
  width: 432px;
  height: 432px;
  position: absolute;
  text-align: center;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.version {
  background-color: white;
  width: 40px;
  margin: 10px auto;
  display: block;
  font-size: 8px;
  color: gray;
  border-radius: 5px;
}

.back-button.back-button {
  position: absolute;
  left: 20px;
  top: 20px;
}

.welcome-box.form {
  background: white;
}

.login-form.login-form {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  text-align: left;
  margin: auto;
  width: 385px;
  height: 245PX;
  align-items: center;
}

.login-form>Button {
  margin: auto;
  display: block;
}

.login-form #email,
.login-form #password {
  width: 241px;
}

.login-form #email {
  color: black;
}

.ui-button__content.ui-button__content {
  font-weight: normal;
}

@media only screen and (max-width: 768px) {
  .welcome-box {
    width: 290px;
  }

  .welcome-box.form {
    background: white;
  }

  .welcome-box img {
    width: 227px;
  }

  .welcome-box h1 {
    font-size: 21px;
  }

  .welcome-box .ui-form__label {
    font-size: 14px;
    font-weight: 600;
  }

  .welcome-box .ui-input__input.ui-input__input {
    background: white;
    outline: solid 1px gray;
    margin-top: 5px;
  }

  .welcome-box p {
    font-size: 14px;
    padding: 14px 0 16px 0;
  }

  .main-button {
    padding: 18px;
  }
}